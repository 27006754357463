<template>
  <section>
    <div class="wrapper">
      <div class="image-title-section">
        <img src="../../assets/icons/Badge.svg" alt="" class="error-image">

        <h3>{{ localize('error')['successPage']['title'] }}</h3>
      </div>

      <div class="button-section">
        <main-button
          class="checkStatusButton"
          :label="localize('button')['back']"
          @click="makeRoute"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MainButton from '../../components/buttons/MainButton';
import localize from '../../filters/localize.filter';

export default {
  name: 'ErrorPage',
  components: { MainButton },
  created() {
    this.$store.dispatch('buyers/userStatus');
  },
  methods: {
    localize,
    makeRoute() {
      this.$toasted.clear();
      this.$router.push({ name: 'profile.contracts' });
    },
  },
};
</script>

<style lang="scss" scoped>
  section {
    background: $grey;
    height: 100vh;
    padding-top: 80px;

    .wrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 575px;
      margin: 0 auto;

      .error-image {
        margin: 0 auto;

        @media (max-width: $mobile) {
          width: 225px;
        }
      }

      h3 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        margin: 67px 0;
        color: $black;

        @media (max-width: $mobile) {
          margin: 24px 0 0;
        }
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: $black;
        margin-top: 24px;
      }

      .checkStatusButton {
        width: auto;
        padding: 18px 56px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 575px) {
    section {
      padding-top: 28px;

      .wrapper {
        max-width: 320px;
        height: 100%;
        justify-content: space-between;

        .button-section {
          margin-bottom: 150px;
        }

        .error-image {
          width: 250px;
        }

        h3 {
          font-size: 24px;
          line-height: 30px;
        }

        p {
          font-size: 15px;
          line-height: 24px;
        }

        .checkStatusButton {
          width: 100%;
        }
      }
    }
  }
</style>
